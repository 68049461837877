import { useCustomAsyncStoryblok } from "./CustomAsyncStoryblok";
import { useRuntimeConfig } from 'nuxt/app'

export async function useWhatsappURL() {
    const config = useRuntimeConfig();

    const settings = await useCustomAsyncStoryblok("cdn/datasource_entries", {
        datasource: `${config.public["storyblokCountryPrefix"]}-country-settings`,
        cv: Date.now(),
        per_page: 1000,
      })
        .then((response) => response.value?.datasource_entries)
        .catch((error) => {
        console.log(error);
      });

  const whatsappURL = settings?.filter((entry) => {
    return entry.name === "chat.whatsapp.url";
  });

  return whatsappURL && whatsappURL.length ? whatsappURL[0]?.value : null;
}
